import React, {useState} from 'react';
import {navigate} from 'gatsby';
import {Button, Form, Grid, Header, Image, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import useStore from '../hooks/use-store';
import logo from '../images/logo.png';
import Status from '../components/admin/Status';

const Login = ({location}) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const authStore = useStore('authStore');

  const redirectTo = location?.state?.redirectTo || '/';
  const asAdmin = location?.state?.asAdmin;

  if (authStore.isAuthenticated) {
    if (asAdmin ? authStore.canAccessAdmin : false) {
      // User is authorized. Redirect to destination
      // https://www.gatsbyjs.org/docs/gatsby-link/#replace-history-during-programmatic-navigation
      return navigate(redirectTo, {replace: true});
    }
    // User is not authorized. Log-out the user and go home
    return authStore.logout().then(() => navigate('/', {replace: true}));
  }

  const signin = async () => {
    try {
      await authStore.login(login, password);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Grid textAlign="center" style={{height: '100vh'}} verticalAlign="middle">
      <Grid.Column style={{maxWidth: 450}}>
        <Header as="h2" textAlign="center">
          <Image src={logo} centered />
          Austin Home Properties
        </Header>
        {error && (
          <Status
            className="small-bm"
            errors={error}
            header="Error logging in"
          />
        )}
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              value={login}
              onChange={ev => setLogin(ev.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              onChange={ev => setPassword(ev.target.value)}
            />

            <Button fluid className="primary" size="large" onClick={signin}>
              Log In
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default observer(Login);
